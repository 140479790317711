
.service {
    min-height: 100vh;
    background: #F1C21B;

    .service--title {
        margin: 0 15%;
        margin-top: -17.5rem;
        padding-bottom: 2.5rem;
        text-align: center;

        h2 {
            font-size: 3.2rem;
            font-weight: 600;
            color: black;
        }
    }

    .box--holder {
        display: flex;
        gap: 0 7rem;
        padding-top: 5rem;
        padding-bottom: 12.5rem;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;

        .box {
            width: 30rem;
            height: 35rem;
            display: flex;
            position: relative;
            align-items: center;
            background: #2E2E2E;
            justify-content: center;

            .content {
                width: 100%;
                height: 100%;
                display: flex;
                gap: 2rem;
                padding: 0 2rem;
                overflow: hidden;
                position: relative;
                text-align: center;
                align-items: center;
                flex-direction: column;
                justify-content: center;

                .icon {
                    width: 8rem;
                    height: 8rem;
                    display: flex;
                    color: var(--clr);
                    font-size: 4.5rem;
                    align-items: center;
                    background: #2E2E2E;
                    justify-content: center;
                    transition: 0.5s ease-in-out;
                    box-shadow: 0 0 0 4px #2E2E2E, 0 0 0 6px var(--clr);

                    i {
                        font-size: 5rem;
                        color: #d1c2c2;
                    }
                }

                .text {
                    h3 {
                        color: #fff;
                        font-weight: 500;
                        font-size: 1.5rem;
                        transition: 0.5s ease-in-out;
                    }

                    p {
                        color: #777;
                        transition: 0.5s ease-in-out;
                    }

                    a {
                        position: relative;
                        color: #2E2E2E;
                        font-weight: 500;
                        margin-top: 10px;
                        padding: 8px 1.5rem;
                        display: inline-block;
                        text-decoration: none;
                        background: var(--clr);
                        transition: 0.5s ease-in-out;
                    }
                }

            }

            &::before {
                content: '';
                z-index: 1;
                position: absolute;
                inset: -1rem 5rem;
                transform: skewY(15deg);
                transition: 0.5s ease-in-out;
                border-top: 5px solid var(--clr);
                border-bottom: 5px solid var(--clr);
            }

            &:hover::before {
                inset: -10px 40px;
                transform: skewY(0deg);
            }

            &::after {
                content: '';
                position: absolute;
                inset: 6rem -1rem;
                border-left: 5px solid var(--clr);
                border-right: 5px solid var(--clr);
                z-index: 1;
                transform: skew(15deg);
                transition: 0.5s ease-in-out;
            }

            &:hover::after {
                transform: skew(0deg);
                inset: 40px -10px;
            }

            &:hover .content .icon {
                background: var(--clr);
                color: #2E2E2E;
                box-shadow: 0 0 0 4px #2E2E2E, 0 0 0 30rem var(--clr);
            }

            &:hover .content .text h3 {
                color: #2E2E2E;
            }

            &:hover .content .text p {
                color: #2E2E2E;
            }

            &:hover .content .text a {
                background: #2E2E2E;
                color: var(--clr);
            }

        }

    }

}
