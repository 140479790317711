
footer {

    .footer--wave {
        width: 100%;
        height: 2vh;
        position: relative;

        .wave {
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 10rem;
            background-size: 100rem 10rem;
            background-image: url("../../images/wave.png");
            animation: footer-wave 5s ease-in-out infinite;
            animation-delay: calc(var(--i) * 0.25s);
        }

        .wave#wave1 {
            opacity: 1;
            z-index: 999;
            background-position-x: 40rem;
            background-color: transparent;
        }

        .wave#wave2 {
            opacity: 0.5;
            z-index: 998;
            background-position-x: 30rem;
            background-color: transparent;
        }

        .wave#wave3 {
            z-index: 997;
            opacity: 0.25;
            background-position-x: 20rem;
            background-color: transparent;
        }

        .wave#wave4 {
            z-index: 996;
            opacity: 0.75;
            background-position-x: 10rem;
            background-color: transparent;
        }

        @keyframes footer-wave {
            0%, 100% {
                transform: translateY(0px);
            }

            50% {
                transform: translateY(10px);
            }

        }

    }

    .footer--content {
        width: 50%;
        margin: 0 auto;
        margin-top: 7.5rem;

        .icons {
            text-align: center;
            margin-bottom: 2.5rem;

            img {
                width: 8rem;
                height: 8rem;
            }
        }

        .logo {
            display: flex;
            gap: 0 .3rem;
            text-align: center;
            padding-bottom: 1rem;

            .letter--icons {
                width: 3.6rem;
                height: 3.6rem;
                text-align: center;
                padding-top: .3rem;
                border-radius: .5rem;

                img {
                    width: 3.2rem;
                    height: 3.2rem;
                }
            }

            .letter {
                width: 3.6rem;
                height: 3.6rem;
                text-align: center;
                padding-top: .4rem;
                border-radius: .5rem;
                box-shadow: 1px 1px 24px 40px rgba(236, 240, 10, 0.925) inset;

                span {
                    font-size: 2.4rem;
                    font-weight: 700;
                    color: rgb(92, 75, 20);
                }

                &.pt-5 {
                    padding-top: .7rem;
                }

                &.npt-5 {
                    padding-bottom: .7rem;
                }

                &.mt-5 {
                    margin-top: .25rem;
                }
            }

        }

        .social-icons {
            display: flex;
            gap: 0 .5rem;
            padding: 2.5rem 0;

            li {
                text-align: center;
                border-radius: .5rem;

                i {
                    font-size: 2.8rem;
                    color: blue;
                    padding: .25rem .5rem;
                }
            }
        }

        .footer--menu {
            display: flex;
            gap: 0 .5rem;
            text-align: center;
            padding-bottom: 2.5rem;

            a {
                li {
                    cursor: pointer;
                    padding: 0 2rem;
                    border-right: 2px solid rgb(51, 3, 3);

                    span {
                        font-size: 1.4rem;
                        font-weight: 500;
                        color: rgb(63, 9, 9);
                    }

                    &:hover span {
                        text-decoration: underline;
                    }
                }
            }

            a:nth-last-of-type(1) li {
                border-right: none;
            }

        }

        ul .space {
            flex: 1;
        }

        .footer--copyright {
            padding: 2rem 0;
            text-align: center;
            font-size: 1.2rem;
            font-weight: 400;
            color: black;
            letter-spacing: .15rem;
            border-top: 1px dotted orange;
        }

    }

}