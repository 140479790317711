
.team {
    min-height: 100vh;
    padding-bottom: 5rem;
    position: relative;
    background: #1068AF;

    .header {
        margin: 0 7.5%;
        padding: 0 10rem;
        margin-top: -12.5rem;
        margin-bottom: 2.5rem;
        text-align: center;

        span {
            font-size: 3.2rem;
            font-weight: 600;
            color: rgb(224, 237, 238);
        }

    }

    .svg--one {
        position: absolute;
        top: -5rem;
        left: 2.5rem;
    }

    .svg--two {
        position: absolute;
        top: 12.5rem;
        right: 0;
    }

    .svg--three {
        position: absolute;
        bottom: -12.5rem;
        right: 7.5rem;
    }

    .svg--four {
        position: absolute;
        bottom: 10rem;
        right: 62.5rem;
    }

    .svg--five {
        position: absolute;
        left: -2.5rem;
        bottom: -7.5rem;
    }

    .card--container {
        margin: 0 7.5%;
        display: flex;
        flex-direction: column;
        gap: 1.5rem 1rem;

        .row {
            display: flex;
            justify-content: space-evenly;
            padding: 1rem 0;

            .profile--card {
                position: relative;
                width: 25%;
                height: 50vh;
                cursor: pointer;
                transform-style: preserve-3d;
                transform: perspective(100rem);
                box-shadow: 10px 20px 40px rgba(0, 0, 0, 0.25);
                transition: 1s;
                z-index: 999;

                .imgBox {
                    position: relative;
                    width: 100%;
                    height: 100%;
                    z-index: 1;
                    transform-origin: left;
                    transform-style: preserve-3d;
                    transition: 1s;

                    .before--rotate {
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;
                        background: rgb(182, 179, 40);
                        transform-style: preserve-3d;
                        backface-visibility: hidden;

                        img {
                            width: 100%;
                            height: 100%;
                            clip-path: circle(107.5% at 0 2%);
                        }

                        h2 {
                            position: absolute;
                            bottom: 1rem;
                            right: 1rem;
                            font-size: 1.6rem;
                            font-weight: 500;
                            text-align: right;

                            span {
                                font-size: 1.4rem;
                                font-weight: 500;
                            }
                        }
                    }

                    .after--rotate {
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;
                        display: flex;
                        gap: 0 .5rem;
                        padding: 0 .5rem;
                        background: rgba(193, 207, 202, 0.5);
                        justify-content: space-between;
                        transform-style: preserve-3d;
                        backface-visibility: hidden;

                        .social-icons {
                            display: flex;
                            flex-direction: column;
                            gap: .25rem 0;
                            padding: .5rem .5rem;
                            position: relative;

                            li {
                                width: 2.8rem;
                                height: 2.8rem;
                                transition: 0.2s;
                                text-align: center;
                                border-radius: .3rem;

                                i {
                                    font-size: 1.8rem;
                                    color: blue;
                                }
                            }

                            .space {
                                flex: 1;
                                border: none;
                            }

                            .submenu {
                                display: flex;
                                gap: 0 2rem;

                                .sidemenu {
                                    padding: .5rem 1rem;
                                    height: 2.8rem;
                                    background: #d3d2cb;
                                    z-index: 999;
                                    opacity: 1;
                                    font-size: 1rem;
                                    font-weight: 500;
                                    letter-spacing: .1rem;
                                    transform: rotate(0deg);
                                    border-radius: .5rem;
                                }
                            }
                        }

                        img {
                            width: 87.5%;
                            height: 100%;
                            clip-path: ellipse(100% 100% at 100% 50%);
                        }

                    }

                    div:nth-child(2) {
                        transform: rotateY(180deg);
                    }

                }

                &:hover {
                    transform: translateX(50%);
                }

                &:hover .imgBox {
                    transform: rotateY(-180deg);
                }

                .details {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    background-image: url('../../images/wood2.jpg');
                    background-repeat: no-repeat;
                    background-position: center;
                    background-size: cover;

                    .content {
                        width: 65%;
                        height: 65%;
                        background: rgba(240, 236, 236, 0.5);
                        clip-path: polygon(20% 0%, 80% 0%, 100% 20%, 100% 80%, 80% 100%, 20% 100%, 0% 80%, 0% 20%);

                        h4 {
                            text-align: center;
                            padding: 2rem 2.5rem;

                            i {
                                font-size: 3rem;
                                font-weight: 600;
                                color: rgb(50, 78, 4);
                            }

                            span {
                                font-size: 1.6rem;
                                font-weight: 400;
                                color: rgb(50, 78, 4);
                            }
                        }
                    }
                }

            }
        }

        @keyframes animi-gradient {
            0% {
                background-position: 0 50%;
            }
            50% {
                background-position: 50% 100%;
            }
            100% {
                background-position: 0% 50%;
            }
            
        }
    }

}
